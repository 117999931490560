import React from 'react';
import range from 'lodash/utility/range';
import classnames from 'classnames';
import LoadMore from '../../common/load-more';
import ResultComponent from '../result/result';
import { getState } from '../../../store';
import ResultsError from './error';
import ResultPlaceholder from '../result/placeholder';

const numberOfPlaceholders = 16;

const ResultCards = (props) => {
  const renderPlaceHolders = () => range(numberOfPlaceholders).map((key) => <ResultPlaceholder key={key} />);

  const renderCards = (results, curatedBanner, isNewShareWorkflow) => {
    return results.map((learningObject) => (
      <ResultComponent
        key={learningObject.id}
        accountId={props.session.accountId}
        curatedBanner={curatedBanner}
        allowApprovedContent={props.session.allowApprovedContent}
        isNewShareWorkflow={isNewShareWorkflow}
        learningObject={learningObject}
        search={props.search}
      />
    ));
  };

  const isLoading = props.results.store.showLoading();
  const results = props.results.store.get();
  const fetchNext = () => props.fetchNext(props.results.nextCursor);
  const listClassnames = classnames('SearchResults-list', {
    'SearchResults-list-loading': props.results.loading
  });

  const isNewShareWorkflow = getState().featureFlags.flags.new_share_workflow;

  return (
    <div className="SearchResults">
      <div className={listClassnames}>
        <LoadMore hasMore={props.results.hasMore} isLoading={isLoading} loadMore={fetchNext}>
          {results.length
            ? renderCards(results, props.curatedBanner, isNewShareWorkflow)
            : undefined}
        </LoadMore>
        {props.results.error && <ResultsError onRetryClick={fetchNext} />}
      </div>
      <div className="SearchResults-list-placeholder">
        <div className="LoadMore">
          {isLoading && renderPlaceHolders()}
        </div>
      </div>
    </div>
  );
};

export default ResultCards;
