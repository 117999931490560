import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AnalyticsActions from '../../../actions/analytics';
import RouterStore from '../../../stores/router';
import { getState } from '../../../store';
import { CARD_VIEW } from '../../../constants/results-view';
import SearchInfo from '../info';
import ResultCards from './cards';
import ResultsList from './list';
import { SORTING_ORDER_DESCENDING, SORT_MATCHING, SORT_RECENT } from '../../../constants/search';

const SearchResultsWrapper = (props) => {
  const [isNewSearch, setIsNewSearch] = useState(true);
  const router = useRef();
  const sortBy = RouterStore.state.query.sortBy || (RouterStore.state.query.q ? SORT_MATCHING : SORT_RECENT);
  const sortOrder = RouterStore.state.query.sortOrder || SORTING_ORDER_DESCENDING;
  const fetchNext = (cursor) => props.fetchNext({ ...props.baseParams, ...props.router.query }, cursor);
  const showEmptyMessage = props.results.store.showEmpty();
  const statsMessage = showEmptyMessage ? '' : props.statsMessage;
  const isCardView = getState().search.resultsView === CARD_VIEW;
  const account = props.account;
  const curatedBanner = {
    color: (account && account.settings.accountCuratedBannerColor) || null,
    image: (account && account.settings.accountCuratedBannerImage) || null
  };

  useEffect(() => {
    if (isNewSearch && props.results.searchSuccessful) {
      setIsNewSearch(false);
      AnalyticsActions.viewedSearchResults(props.results.count);
    }

    if (router.current !== props.router) {
      router.current = props.router;
      props.fetchNext({
        ...props.baseParams,
        ...props.router.query
      });
      setIsNewSearch(true);
    }
  }, [props]);

  const renderCards = () => {
    return (
      <ResultCards
        {...props}
        fetchNext={fetchNext}
        curatedBanner={curatedBanner}
      />
    );
  };

  const renderList = () => {
    return (
      <ResultsList
        results={props.results.store.get()}
        curatedBanner={curatedBanner}
        allowApprovedContent={props.session.allowApprovedContent}
        accountId={props.session.accountId}
        sortOrder={sortOrder}
        sortBy={sortBy}
      />
    );
  };

  const renderResults = () => {
    return isCardView ? renderCards() : renderList();
  };

  return (
    <div>
      <SearchInfo {...props} statsMessage={statsMessage} includeToggleResultsViewButton />
      {showEmptyMessage
        ? props.emptyMessage
        : renderResults()}
    </div>
  );
};

SearchResultsWrapper.defaultProps = {
  baseParams: {}
};

SearchResultsWrapper.propTypes = {
  baseParams: PropTypes.object,
  fetchNext: PropTypes.func.isRequired,
  results: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  banner: PropTypes.node,
  emptyMessage: PropTypes.node.isRequired,
  statsMessage: PropTypes.node,
  searchPrivateObjects: PropTypes.bool,
  query: PropTypes.string,
  queryArialLabel: PropTypes.string
};

export default SearchResultsWrapper;
