import React from 'react';
import PropTypes from 'prop-types';
import { TruncateText } from '@instructure/ui-truncate-text/lib';
import { View } from '@instructure/ui-view';
import { Tooltip } from '@instructure/ui-tooltip';
import ScreenReaderContent from '@instructure/ui-a11y-content/lib/ScreenReaderContent';

class TruncateWithTooltip extends React.Component {
  state = {
    isTruncated: false
  }

  handleUpdate = (isTruncated) => {
    if (this.state.isTruncated !== isTruncated) {
      this.setState({ isTruncated });
    }
  }

  renderLink () {
    return (
      <div tabIndex="1">
        <ScreenReaderContent>{this.props.text}</ScreenReaderContent>
        <TruncateText onUpdate={this.handleUpdate}>
          <div data-automation="truncatedText" aria-hidden="true" data-testid="truncated-text">
            {this.props.text}
          </div>
        </TruncateText>
      </div>
    );
  }

  render () {
    return (
      <View
        as="div"
        className={this.props.className}
      >
        {this.state.isTruncated ? (
          <Tooltip
            placement="bottom"
            color="inverse"
            renderTip={
              <div className="TruncateWithTooltip-tooltip">
                {this.props.text}
              </div>
            }
          >
            {this.renderLink()}
          </Tooltip>
        ) : this.renderLink()}
      </View>
    );
  }
}

TruncateWithTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default TruncateWithTooltip;
