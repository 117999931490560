import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@instructure/ui-flex';
import { View } from '@instructure/ui-layout';
import SearchActions from '../../actions/search';
import { getState } from '../../store';
import SearchStats from './search-stats';
import FilterPills from './filter-pills';
import SearchControls from './search-controls';
import ToggleResultsViewButton from './toggle-results-view-button';
import CommonsResponsiveFlex from '../common/responsive-flex';

export default class SearchInfo extends React.Component {
  static propTypes = {
    results: PropTypes.object,
    statsMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    consortiums: PropTypes.object,
    groups: PropTypes.object,
    router: PropTypes.object,
    includeToggleResultsViewButton: PropTypes.bool,
  }

  hasFilterPills () {
    if (!this.props.router || !this.props.router.query) {
      return false;
    }

    return this.props.router.query.authorIds ||
      this.props.router.query.authorName ||
      (this.props.router.query.accountId && this.props.router.query.accountName) ||
      this.props.router.query.contentCategoryTypes ||
      this.props.router.query.gradeIds ||
      this.props.router.query.types ||
      (this.props.router.query.scopeIds && /(group-|consortium-|:)/.test(this.props.router.query.scopeIds));
  }

  hasSearchStats () {
    return (this.props.results.count || this.props.statsMessage) && !this.props.results.store.showLoading();
  }

  canShowToggleResultsViewButton () {
    const isListViewEnabled = getState().featureFlags.flags.search_page_list_view;

    return !this.props.results.store.showLoading() && this.props.includeToggleResultsViewButton && isListViewEnabled;
  }

  renderFilterPills () {
    return (
      <View as="div" margin="none none medium">
        <SearchControls query={this.props.router.query} />
        <View as="div" margin="small none none">
          <FilterPills
            query={this.props.router.query}
            account={this.props.session.account}
            consortiums={this.props.consortiums}
            groups={this.props.groups}
            canShowPublic={this.props.canShowPublic}
            onClose={SearchActions.update}
          />
        </View>
      </View>
    );
  }

  renderStatsWithToggleResultsViewButton () {
    return (
      <CommonsResponsiveFlex>
        <Flex.Item align="stretch">
          <SearchStats
            isLoading={this.props.results.store.showLoading()}
            count={this.props.results.count}
            message={this.props.statsMessage}
          />
        </Flex.Item>
        {this.canShowToggleResultsViewButton() && (
          <Flex.Item overflowY="visible">
            <ToggleResultsViewButton />
          </Flex.Item>
        )}
      </CommonsResponsiveFlex>
    );
  }

  render () {
    return (
      <View as="div" padding="medium none">
        {this.hasFilterPills() && this.renderFilterPills()}
        {this.hasSearchStats() && this.renderStatsWithToggleResultsViewButton()}
      </View>
    );
  }
}
