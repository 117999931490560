import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { getState } from '../../store';
import * as searchPaths from '../../constants/search-paths';

// Components
import SearchResultsWrapper from './results/wrapper';
import NoResult from '../common/no-result';

// Actions
import SearchActions from '../../actions/search';

// Stores
import ResultsStore from '../../stores/results';

export default class Search extends React.Component {
  static displayName = 'Search'

  static propTypes = {
    account: PropTypes.object,
    results: PropTypes.object,
    router: PropTypes.object,
    session: PropTypes.object,
    query: PropTypes.string
  }

  render () {
    const state = getState();
    const { query, results, account, session } = this.props;
    const noResultsMessage = results.total
      ? formatMessage('No Results Found')
      : formatMessage('No Resources To Show');

    const stateQuery = state.router.query;

    const isFeaturedByQuery = !stateQuery.q && (stateQuery.sortBy === 'date' || !stateQuery.sortBy);
    const showGlobalFeaturedContent = state.account.settings.canShowFeatured && isFeaturedByQuery;

    let baseParams;
    if (showGlobalFeaturedContent || isFeaturedByQuery) {
      baseParams = { exportStatus: 'complete', showGlobalFeaturedContent, prioritizeFeaturedContent: isFeaturedByQuery };
    } else {
      baseParams = { exportStatus: 'complete' };
    }

    const fetchResults = (params, cursor) => SearchActions.fetchResultsWithPath(params, searchPaths.RESOURCES, { cursor });

    return (
      <SearchResultsWrapper
        query={query}
        baseParams={baseParams}
        fetchNext={fetchResults}
        isLoading={ResultsStore.showLoading()}
        queryArialLabel={formatMessage(
          'Search Commons for course resources by tag, name, institution, or outcome'
        )}
        searchPrivateObjects
        emptyMessage={<NoResult title={noResultsMessage} />}
        canShowPublic={
          account.settings.canShowPublic && session.sid && !session.publicOnly
        }
        {...this.props}
      />
    );
  }
}
